




























































import Vue from 'vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import { validationMixin } from 'vuelidate';
import draggable from 'vuedraggable';
import { PlayroomHighlightDto } from '@/models/PlayroomHighlightDto';
import { maxLength, required } from 'vuelidate/lib/validators';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import DeleteButton from '@/components/forms/DeleteButton.vue';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';

class PlayroomEditHighlightPartViewModel {
  id!: string;
  highlights!: PlayroomHighlightDto[];
}

const validations = {
  playroom: {
    highlights: {
      $each: {
        name: {
          maxLength: maxLength(450),
          required,
        },
      },
    },
  },
};

export default Vue.extend({
  components: {
    ValidatedFormCard,
    draggable,
    ValidatedTextArea,
    DeleteButton,
  },
  validations,
  mixins: [validationMixin, HelpCardMixin],
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errorMessage: '',
      ready: false,
      playroom: {} as PlayroomEditHighlightPartViewModel,
      showDeleteModel: false,
      rowIndex: '',
      focusFor: '',
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    stillAvailableCount(): number {
      return 10 - this.playroom.highlights.length;
    },
    canAdd(): boolean {
      return !this.$v.$invalid && this.stillAvailableCount > 0;
    },
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getHighlight(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditHighlightPartViewModel;
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    addNewHighlight(): void {
      if (this.canAdd) {
        this.playroom.highlights.push(new PlayroomHighlightDto());
      }
    },

    onDeletedHighlight(index: string) {
      this.showDeleteModel = false;
      this.playroom.highlights.splice(+index, 1);
      this.$v.$touch();
    },

    deleteHighlight(id: number) {
      this.rowIndex = id.toString();
      this.showDeleteModel = true;
    },

    closeDeleteModel() {
      this.showDeleteModel = false;
    },

    externalSave(): Promise<boolean> {
      return (this.$refs.validatedFormCard as any).externalSave();
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.playroomEditParts.HighlightPart.info.box1';
      }
    },

    async save(): Promise<boolean> {
      this.ready = false;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.playroom.highlights.forEach((item, index) => {
          item.sortOrder = index + 1;
        });
        var response = await OwnerPlayroomService.saveHighlightPart(this.playroomId, this.playroom.highlights);
        if (response.success) {
          this.loadData();
        }
        return response.success;
      } else {
        return false;
      }
    },
  },
});
