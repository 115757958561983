


































import Vue from 'vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import ImagePart from '@/components/playroomEditParts/ImagePart.vue';
import RoomPart from '@/components/playroomEditParts/RoomPart.vue';
import FeaturePart from '@/components/playroomEditParts/FeaturePart.vue';
import HighlightPart from '@/components/playroomEditParts/HighlightPart.vue';
import MessagePart from '@/components/playroomEditParts/MessagePart.vue';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import { PartComponent } from '@/models/PartComponent';
import { MetaInfo } from 'vue-meta';

class PlayroomEditBasicInformationViewModel {
  id!: string;
  name!: string;
}

export default Vue.extend({
  components: { ImagePart, FeaturePart, RoomPart, HighlightPart, MessagePart },
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditBasicInformationViewModel,
      roomPartSaveStatusType: PartSaveStatusType.Unknown,
      featurePartSaveStatusType: PartSaveStatusType.Unknown,
      highlightPartSaveStatusType: PartSaveStatusType.Unknown,
      messagePartSaveStatusType: PartSaveStatusType.Unknown,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    canSave(): boolean {
      return (
        this.roomPartSaveStatusType != PartSaveStatusType.Invalid &&
        this.featurePartSaveStatusType != PartSaveStatusType.Invalid &&
        this.highlightPartSaveStatusType != PartSaveStatusType.Invalid &&
        this.messagePartSaveStatusType != PartSaveStatusType.Invalid
      );
    },
    isDirty(): boolean {
      return (
        this.roomPartSaveStatusType == PartSaveStatusType.Unsaved ||
        this.roomPartSaveStatusType == PartSaveStatusType.Invalid ||
        this.featurePartSaveStatusType == PartSaveStatusType.Unsaved ||
        this.featurePartSaveStatusType == PartSaveStatusType.Invalid ||
        this.highlightPartSaveStatusType == PartSaveStatusType.Unsaved ||
        this.highlightPartSaveStatusType == PartSaveStatusType.Invalid ||
        this.messagePartSaveStatusType == PartSaveStatusType.Unsaved ||
        this.messagePartSaveStatusType == PartSaveStatusType.Invalid
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInDirtyForm()) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostPlayroomEdit.meta.title', { name: this.playroom.name }).toString(),
    };
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditBasicInformation(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditBasicInformationViewModel;
        this.setBreadCrumb();
      });
    },

    setBreadCrumb() {
      const items = [
        { to: { name: 'OwnerPlayroomIndex' }, translationKey: 'pages.host.HostPlayroomEdit.breadcrumb.playrooms' },
        { text: this.playroom.name },
        { translationKey: 'global.change' },
      ];
      this.$store.commit('UiStoreModule/setBreadcrumbItems', items);
    },

    async onNext() {
      (this.$refs.roomPart as PartComponent).$v.$touch();
      (this.$refs.featurePart as PartComponent).$v.$touch();
      (this.$refs.highlightPart as PartComponent).$v.$touch();
      (this.$refs.messagePart as PartComponent).$v.$touch();
      this.$nextTick(async () => {
        const success = await this.saveAndNext();
        if (!success) {
          // Scroll to first error
          document
            .getElementsByClassName('invalid-feedback')[0]
            .scrollIntoView({ block: 'center', behavior: 'smooth', inline: 'nearest' });
        }
      });
    },

    async onBack() {
      this.$router.push({ name: 'OwnerPlayroomEditBasicInformation', params: { playroomId: this.playroomId } });
    },

    async saveAndNext(): Promise<boolean> {
      if (this.canSave) {
        let success = await this.savePart(this.$refs.roomPart as PartComponent, this.roomPartSaveStatusType);
        if (!success) return false;
        success = await this.savePart(this.$refs.featurePart as PartComponent, this.featurePartSaveStatusType);
        if (!success) return false;
        success = await this.savePart(this.$refs.highlightPart as PartComponent, this.highlightPartSaveStatusType);
        if (!success) return false;
        success = await this.savePart(this.$refs.messagePart as PartComponent, this.messagePartSaveStatusType);
        if (!success) return false;
        window.scrollTo(0, 0);
        this.$router.push({ name: 'OwnerPlayroomEditAvailability', params: { playroomId: this.playroomId } });
      }
      return true;
    },

    async savePart(component: PartComponent, partSaveStatusType: PartSaveStatusType): Promise<boolean> {
      switch (partSaveStatusType) {
        case PartSaveStatusType.Unknown:
        case PartSaveStatusType.Unsaved:
        case PartSaveStatusType.Unchanged: {
          return await component.externalSave();
        }
        case PartSaveStatusType.Saved: {
          return true;
        }
        default: {
          return false;
        }
      }
    },

    confirmLeave() {
      return window.confirm(this.$t('global.confirmUnsavedChanges').toString());
    },

    confirmStayInDirtyForm() {
      return this.isDirty && !this.confirmLeave();
    },

    beforeWindowUnload(e: Event) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = true;
      }
    },
  },
});
