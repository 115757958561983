import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/PlayroomImage/';

class OwnerPlayroomImageService {
  delete(playroomImageId: string, playroomId: string) {
    return ApiService.delete(`${API_URL}Delete?playroomImageId=${playroomImageId}&playroomId=${playroomId}`);
  }

  create(playroomImage: {}) {
    return ApiService.post(`${API_URL}Create`, playroomImage);
  }

  update(playroomImage: {}) {
    return ApiService.post(`${API_URL}Update`, playroomImage);
  }
}

export default new OwnerPlayroomImageService();
