




















































































import Vue, { VueConstructor } from 'vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import OwnerPlayroomRoomService from '@/services/owner/OwnerPlayroomRoomService';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import draggable from 'vuedraggable';
import { maxLength, minValue, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import DeleteButton from '@/components/forms/DeleteButton.vue';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';

class PlayroomEditRoomViewModel {
  id!: string;
  roomType!: string;
  name!: string;
  sizeInSquareMeters!: string;
  sortOrder!: number;
}

class PlayroomEditRoomPartViewModel {
  id!: string;
  rooms!: PlayroomEditRoomViewModel[];
  availableRoomTypes!: string[];
}

const validations = {
  playroom: {
    rooms: {
      $each: {
        roomType: { required },
        name: {
          required,
          maxLength: maxLength(20),
        },
        sizeInSquareMeters: {
          minValue: minValue(1),
        },
        sortOrder: {},
      },
    },
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: {
    draggable,
    ValidatedFormCard,
    ValidatedInput,
    ValidatedSelect,
    DeleteButton,
  },
  mixins: [validationMixin, HelpCardMixin],
  validations,
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditRoomPartViewModel,
      ready: false,
      drag: false,
      showDeleteModel: false,
      rowIndex: '',
      focusFor: '',
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    stillAvailableCount(): number {
      return 10 - this.playroom.rooms.length;
    },
    roomTypeOptions(): { value: string; label: string }[] {
      return this.playroom.availableRoomTypes.map((t) => ({
        value: t as string,
        label: this.$t('enums.roomTypes.' + t).toString(),
      }));
    },
  },
  methods: {
    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.playroomEditParts.RoomPart.info.box1';
      }
    },

    loadData() {
      OwnerPlayroomService.getForEditRoomPart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditRoomPartViewModel;
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    closeDeleteModel() {
      this.showDeleteModel = false;
    },

    addNewRoom() {
      if (this.stillAvailableCount > 1) {
        const playroomRoom = new PlayroomEditRoomViewModel();
        playroomRoom.sortOrder = this.playroom.rooms.length;
        playroomRoom.roomType = 'Livingroom';
        playroomRoom.id = '';
        playroomRoom.name = '';
        playroomRoom.sizeInSquareMeters = '10';
        this.playroom.rooms.push(playroomRoom);
      }
    },

    onDeletedPlayroomRoom(index: number) {
      this.showDeleteModel = false;
      this.playroom.rooms.splice(index, 1);
      this.$v.$touch();
    },

    deleteConfirm(id: number) {
      this.rowIndex = id.toString();
      this.showDeleteModel = true;
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.playroom.rooms.forEach((item, index) => {
          item.sortOrder = index + 1;
        });
        var response = await OwnerPlayroomRoomService.update(this.playroomId, this.playroom.rooms);
        return response.success;
      } else {
        return false;
      }
    },

    endDrag(): void {
      this.drag = false;
      this.$v.$touch();
    },
  },
});
