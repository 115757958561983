





















































































































import Vue from 'vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import PlayroomFeature from '@/models/PlayroomFeature';
import PlayroomCustomFeature from '@/models/PlayroomCustomFeature';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import { validationMixin } from 'vuelidate';
import CustomFeatureModal from '@/components/playroomEditParts/CustomFeatureModal.vue';
import DeleteConfirmationModal from '@/components/messages/DeleteConfirmationModal.vue';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';

export class PlayroomEditFeaturePartViewModel {
  id!: string;
  featureCategories!: FeatureCategory[];
  customFeatures!: PlayroomCustomFeature[];
}

export class FeatureCategory {
  categoryTranslationKey!: string;
  features!: PlayroomFeature[];
}

const validations = {
  playroom: {
    featureCategories: {
      $each: {
        features: {
          $each: {
            id: {},
            translationKey: {},
            hasFeature: {},
            hasFee: {},
          },
        },
      },
    },
    customFeatures: {
      $each: {
        id: {},
        hasFee: {},
        customName: {},
      },
    },
  },
};

export default Vue.extend({
  components: { ValidatedFormCard, ValidatedCheckbox, CustomFeatureModal, DeleteConfirmationModal },
  mixins: [validationMixin],
  validations,
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditFeaturePartViewModel,
      errorMessage: '',
      ready: false,
      feeMessage: this.$t('components.playroomEditParts.FeaturePart.feeTooltip').toString(),
      noFeeMessage: this.$t('components.playroomEditParts.FeaturePart.noFeeTooltip').toString(),
      openModalForCustomPlayroomFeature: null as PlayroomCustomFeature | null,
      objectPointer: {} as { customName: { $model: string }; hasFee: { $model: boolean } },
      showModal: false,
      message: this.$t('components.playroomEditParts.FeaturePart.deleteMessage').toString(),
      rowIndex: -1,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    onOk(customFeature: PlayroomCustomFeature) {
      if (customFeature.id == '-1') {
        customFeature.id = '';
        if (this.$v.playroom.customFeatures) {
          this.$v.playroom.customFeatures.$model.push(customFeature);
          this.$v.$touch();
        }
      } else {
        this.objectPointer.customName.$model = customFeature.customName;
        this.objectPointer.hasFee.$model = customFeature.hasFee;
      }
      this.openModalForCustomPlayroomFeature = null;
    },

    onCancel() {
      this.openModalForCustomPlayroomFeature = null;
    },

    loadData() {
      OwnerPlayroomService.getForEditFeaturePart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditFeaturePartViewModel;
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    openItemModal(feature: {
      $model: PlayroomCustomFeature;
      customName: { $model: string };
      hasFee: { $model: boolean };
    }) {
      this.objectPointer = feature as any;
      this.openModalForCustomPlayroomFeature = { ...feature.$model };
    },

    openNewItemModal() {
      const feature = new PlayroomCustomFeature();
      feature.id = '-1';
      feature.playroomId = this.playroomId;
      feature.hasFee = false;
      feature.customName = '';
      feature.sortOrder = this.playroom.customFeatures.length + 1;
      this.openModalForCustomPlayroomFeature = feature;
    },

    switchFee(hasFee: { $model: boolean }) {
      hasFee.$model = !hasFee.$model;
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        for (let customerFeature of this.playroom.customFeatures) {
          customerFeature.sortOrder = this.playroom.customFeatures.indexOf(customerFeature);
        }
        const response = await OwnerPlayroomService.saveFeaturePart(
          this.playroom.id,
          this.playroom.featureCategories.flatMap((fc) => fc.features),
          this.playroom.customFeatures
        );
        if (response.success) {
          this.loadData();
          this.$emit('part-save-status-type-change', PartSaveStatusType.Saved);
          this.$v.$reset();
        }
        return response.success;
      } else {
        return false;
      }
    },

    openModal(index: number) {
      this.rowIndex = index;
      this.showModal = true;
    },

    closeModal() {
      this.rowIndex = -1;
      this.showModal = false;
    },

    internalOnDelete() {
      let features = this.$v.playroom?.customFeatures?.$model as PlayroomFeature[];
      if (features) {
        features.splice(this.rowIndex, 1);
        this.$v.$touch();
      }
      this.closeModal();
    },
  },
});
