import ApiService, { ServerResponse } from '@/services/ApiService';

const API_URL = '/api/Owner/PlayroomRoom/';

class OwnerPlayroomRoomService {
  delete(playroomRoomId: string, playroomId: string) {
    return ApiService.delete(`${API_URL}Delete?playroomRoomId=${playroomRoomId}&playroomId=${playroomId}`);
  }

  create(
    playroomRoom: { roomType: string; name: string; sizeInSquareMeters: string; sortOrder: string | number },
    playroomId: string
  ) {
    playroomRoom.sortOrder = String(playroomRoom.sortOrder);
    return ApiService.post(`${API_URL}Create`, { ...playroomRoom, playroomId });
  }

  update(playroomId: string, playroomRooms: {}[]): Promise<ServerResponse> {
    return ApiService.post(`${API_URL}Update?playroomId=${playroomId}`, playroomRooms);
  }
}

export default new OwnerPlayroomRoomService();
