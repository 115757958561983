


























































































import Vue, { VueConstructor } from 'vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import OwnerPlayroomImageService from '@/services/owner/OwnerPlayroomImageService';
import ToastMessage from '@/models/ToastMessage';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import SaveButton from '@/components/forms/SaveButton.vue';
import DeleteButton from '@/components/forms/DeleteButton.vue';
import { validationMixin } from 'vuelidate';
import { maxLength, required } from 'vuelidate/lib/validators';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';
import draggable from 'vuedraggable';
import { validateImageMimeType } from '@/services/MimeTypeChecker';

const validations = {
  playroom: {
    images: {
      $each: {
        title: {
          required,
          maxLength: maxLength(50),
        },
        description: {
          maxLength: maxLength(750),
        },
        dataUrl: {},
        link: {},
      },
    },
  },
};

export class PlayroomImage {
  constructor(playroomId: string) {
    this.title = '';
    this.description = '';
    this.link = '';
    this.id = '';
    this.dataUrl = '';
    this.fileName = '';
    this.playroomId = playroomId;
    this.sortOrder = '0';
  }
  title!: string;
  description!: string;
  link!: string;
  id!: string;
  playroomId!: string;
  dataUrl!: string;
  fileName!: string;
  sortOrder!: string;
}

class PlayroomEditImagePartViewModel {
  id!: string;
  images!: PlayroomImage[];
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: {
    ValidatedFormCard,
    SaveButton,
    ValidatedInput,
    ValidatedTextArea,
    DeleteButton,
    draggable,
  },
  mixins: [validationMixin, HelpCardMixin],
  validations,
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditImagePartViewModel,
      errorMessage: '',
      ready: false,
      activeCollapsePlayroomIndex: 0,
      saving: false,
      drag: false,
      showImageSpinner: false,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    stillAvailableCount(): number {
      if (this.playroom.images == undefined) {
        return 0;
      }
      return 15 - this.playroom.images.length;
    },
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditImagePart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditImagePartViewModel;
        this.playroom.images = this.playroom.images as PlayroomImage[];
        this.ready = true;
        this.activeCollapsePlayroomIndex = 0;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
        this.saving = false;
      });
    },

    onDragChange(event: { moved: { newIndex: number; oldIndex: number } }) {
      if (event.moved) {
        this.playroom.images.forEach((image) => {
          this.saveChangedImage(image, false);
        });
        this.$v.$reset();
      }
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.playroomEditParts.ImagePart.info.box1';
      }
    },

    addNewImage() {
      if (this.stillAvailableCount >= 1) {
        const playroomImage = new PlayroomImage(this.playroomId);
        this.playroom.images.push(playroomImage);
        this.activeCollapsePlayroomIndex = this.playroom.images.length - 1;
      }
    },

    async handleSave(playroomImage: { $reset: () => void; $model: PlayroomImage }) {
      this.saving = true;
      const model = playroomImage.$model;
      if (model.id === '') {
        await this.saveNewImage(model);
      } else {
        await this.saveChangedImage(model);
      }
      return playroomImage.$reset();
    },

    handleFileInput(event: any) {
      this.showImageSpinner = true;
      const target = event.target as HTMLInputElement;
      // If Files are uploaded
      if (target.files && target.files[0]) {
        var reader = new FileReader();

        // Success Read Callback
        reader.onload = () => {
          const image = this.playroom.images[this.activeCollapsePlayroomIndex];
          const dataUrl = reader.result as string;
          validateImageMimeType(dataUrl, (valid: boolean) => {
            if (valid) {
              image.dataUrl = dataUrl;
              this.$v.playroom.images?.$each[this.activeCollapsePlayroomIndex]?.dataUrl.$touch();
            } else {
              this.$store.commit(
                'addToastMessage',
                new ToastMessage(
                  this.$t('components.playroomEditParts.ImagePart.messageImageIsCorrupt').toString(),
                  'bg-warning'
                )
              );
            }
            this.showImageSpinner = false;
          });
        };

        // Read first file
        reader.readAsDataURL(target.files[0]); // convert to base64 string
      }
    },

    validateImage(dataUrl: string): Promise<void> {
      const base64 = dataUrl.split(',')[1];
      const text = atob(base64);
      console.log(text);
      return Promise.resolve();
    },

    saveNewImage(playroomImage: PlayroomImage) {
      playroomImage.sortOrder = this.playroom.images.indexOf(playroomImage).toString();
      if (!playroomImage.dataUrl) {
        this.$store.commit(
          'addToastMessage',
          new ToastMessage(this.$t('components.playroomEditParts.ImagePart.noDataUrl').toString(), 'bg-warning')
        );
        return Promise.reject();
      }
      return OwnerPlayroomImageService.create({
        ...playroomImage,
        dataUrl: playroomImage.dataUrl,
        filename: playroomImage.fileName,
      }).then(
        (value) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('components.playroomEditParts.ImagePart.saveSuccess').toString(), 'bg-success')
          );
          this.errorMessage = '';
          this.loadData();
          return value;
        },
        (error) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('components.playroomEditParts.ImagePart.saveFailed').toString(), 'bg-warning')
          );
          this.errorMessage = error;
          this.saving = false;
        }
      );
    },

    saveChangedImage(playroomImage: PlayroomImage, showToasterMessage = false) {
      playroomImage.sortOrder = this.playroom.images.indexOf(playroomImage).toString();
      return OwnerPlayroomImageService.update({
        ...playroomImage,
        dataUrl: playroomImage.dataUrl,
        filename: playroomImage.fileName,
      }).then(
        (value) => {
          if (showToasterMessage) {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('components.playroomEditParts.ImagePart.saveSuccess').toString(), 'bg-success')
            );
          }
          this.errorMessage = '';
          this.saving = false;
          return value;
        },
        (error) => {
          if (showToasterMessage) {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('components.playroomEditParts.ImagePart.saveFailed').toString(), 'bg-warning')
            );
          }
          this.errorMessage = error;
          this.saving = false;
        }
      );
    },

    handleDelete: function (playroomImageId: string, index: number) {
      if (playroomImageId) {
        OwnerPlayroomImageService.delete(playroomImageId, this.playroom.id).then(
          () => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('components.playroomEditParts.ImagePart.deleteSuccess').toString(), 'bg-success')
            );
            this.errorMessage = '';
            this.playroom.images.splice(index, 1);
          },
          (error) => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('components.playroomEditParts.ImagePart.deleteFailed').toString(), 'bg-warning')
            );
            this.errorMessage = error;
          }
        );
      } else {
        this.playroom.images.splice(index, 1);
      }
    },

    setDataUrl(dataUrl: string): void {
      const image = this.playroom.images[this.activeCollapsePlayroomIndex];
      image.dataUrl = dataUrl;
    },
  },
});
