



























import Vue, { PropType } from 'vue';
import { validationMixin } from 'vuelidate';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import { maxLength, required } from 'vuelidate/lib/validators';
import PlayroomCustomFeature from '@/models/PlayroomCustomFeature';

const validations = {
  feature: {
    customName: {
      maxLength: maxLength(50),
      required,
    },
    hasFee: {},
    id: {},
  },
};

export default Vue.extend({
  mixins: [validationMixin],
  components: {
    ValidatedCheckbox,
    ValidatedInput,
  },
  validations,
  props: {
    feature: {
      type: Object as PropType<PlayroomCustomFeature>,
      required: true,
    },
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errorMessage: '',
    };
  },
  methods: {
    onOk() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('ok', this.feature);
      }
    },

    onCancel() {
      this.$emit('cancel');
    },
  },
});
