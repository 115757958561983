






























import Vue, { VueConstructor } from 'vue';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import { validationMixin } from 'vuelidate';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import { maxLength } from 'vuelidate/lib/validators';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';

class PlayroomEditMessagePartViewModel {
  id!: string;
  welcomeMessage!: string;
}

const validations = {
  playroom: {
    welcomeMessage: { maxLength: maxLength(700) },
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedTextArea, ValidatedFormCard },
  mixins: [validationMixin, HelpCardMixin],
  validations,
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditMessagePartViewModel,
      ready: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditMessagePart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditMessagePartViewModel;
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.playroomEditParts.MessagePart.info.box1';
      }
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var response = await OwnerPlayroomService.saveMessagePart({ ...this.playroom });
        this.$emit('part-save-status-type-change', PartSaveStatusType.Saved);
        return response.success;
      } else {
        return false;
      }
    },
  },
});
